let $ = require('jquery');

class Alerts {
  displayAlert(name, message, context) {
    let alert = $('#alert-' + name);
    if(alert.length === 0) {
      alert = $(`<div class="alert alert-${context}" id="alert-${name}">${message}<button type="button" class="close" data-dismiss="alert">×</button></div>`);
      this.alertContainer().append(alert);
    }
    else {
      alert.html(message);
    }
  }

  alertContainer() {
    return $('#alert-container');
  }
}

export default Alerts;