// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
window.jQuery = window.$ = require("jquery");
require("@rails/ujs").start()
import "bootstrap";
window.CountUp = require("countup.js");
window.AOS = require("aos");
//require('@fancyapps/fancybox');

import SearchForm from '../components/search_form';
let searchForm = new SearchForm();
searchForm.init();

import OccumatchTables from '../components/occumatch_tables';
let occumatchTables = new OccumatchTables();
occumatchTables.init();

import ResumeSkills from '../components/resume_skills';
let resumeSkills = new ResumeSkills();
resumeSkills.init();

import Alerts from '../components/alerts';
let alerts = new Alerts();

$(document).ready(function() {
  require("channels");
  require("feather-icons");
  require("highlightjs");
  require("jarallax");
  require("smooth-scroll");
  require("typed.js");
  require("theme.js");
  require("@fancyapps/fancybox");
  require("flickity-fade");
  require('bootstrap/js/dist/modal');

  var openEmpRecordForm = function(e) {
    $('.employmentrecord-form-container').html("<div class='text-center'><i class='fe fe-refresh-cw fe-spin'></i></div>");
    $('#modalAddEmphx').modal('show');
    $.ajax({
      url: this.href,
      success: function(data) {
          $('.employmentrecord-form-container').html(data);
          window.initializeAutocomplete();
          window.initializeDatepickers();
      }
    })
    e.preventDefault();
  }

  var openProfileForm = function(e) {
    e.preventDefault();

    $('.profile-form-container').html("<div class='text-center'><i class='fe fe-refresh-cw fe-spin'></i></div>");
    $('#modalUpdateProfile').modal('show');
    $.ajax({
      url: this.href,
      success: function(data) {
        $('.profile-form-container').html(data);
      }
    })
  }

  var saveProfile = function(e) {
    e.preventDefault(e);
    var data = $(this).serialize();
    var action = $(this).attr('action');
    var method = $(this).attr('method');
    $.ajax({
      url: action,
      data: data,
      method: method,
      success: function(data) {
        $('.profile-details-container').html(data);
        $('#modalUpdateProfile').modal('hide');
        alerts.displayAlert('profile-update', 'Your profile has been updated', 'success');
      },
      error: function(data) {
        $('.profile-form-container').html(data.responseText);
      }
    })
  }

  $('.open-profile-form').on('click', openProfileForm);
  $('.profile-form-container').on('submit', 'form', saveProfile);

  $('.employmentrecords-container').on('click', '.edit', openEmpRecordForm);
  $('.new-employment-record').on('click', openEmpRecordForm);

  // countup.js
  // Theme module
  //
  //
  // aos.js
  // Theme module
  //
  (function() {

    //
    // Functions
    //

    function init() {
      var options = {
        duration: 700,
        easing: 'ease-out-quad',
        once: true,
        startEvent: 'load'
      }
      AOS.init(options);
    }

    //
    // Events
    //

    if (typeof AOS !== 'undefined') {
      init();
    }

  })();//

  (function() {

    //
    // Variables
    //

    var toggle = document.querySelectorAll('[data-toggle="countup"]');


    //
    // Functions
    //

    function init(elem) {
      var startVal = (elem.dataset.from) ? +elem.dataset.from : null;
      var endVal = (elem.dataset.to) ? +elem.dataset.to : null;
      var decimals = (elem.dataset.decimals) ? +elem.dataset.decimals : null;
      var duration = (elem.dataset.duration) ? +elem.dataset.duration : null;
      var options = (elem.dataset.options) ? JSON.parse(elem.dataset.options) : null;
      var countUp = new CountUp(elem, startVal, endVal, decimals, duration, options);

      if (!countUp.error) {
        countUp.start();
        elem.classList.add('counted');
      } else {
        console.error(countUp.error);
      }
    }


    //
    // Events
    //

    if (typeof CountUp !== 'undefined' && toggle) {
      [].forEach.call(toggle, function(el) {
        if (el.getAttribute('data-aos-id') !== 'countup:in') {
          init(el);
        }
      });
    }

    document.addEventListener('aos:in:countup:in', function(e) {
      if (e.detail instanceof Element) {
        init(e.detail);
      } else {
        var toCount = document.querySelectorAll('.aos-animate[data-aos-id="countup:in"]:not(.counted)');
        [].forEach.call(toCount, function(el) {
          init(el);
        });
      }
    });

  })();//
  // fancybox.js
  // Theme module
  //

  (function() {

    //
    // Functions
    //

    function globalOptions() {
      $.fancybox.defaults.image.preload = false;
      $.fancybox.defaults.toolbar = false;
      $.fancybox.defaults.clickContent = false;
    }


    //
    // Events
    //

    if (jQuery().fancybox) {
      globalOptions();
    }

  })();//
  // Highlight.js ==================================
  //

  'use strict';

  (function() {

    //
    // Variables
    //

    var toggle = document.querySelectorAll('.highlight');


    //
    // Functions
    //

    function init(el) {
      hljs.highlightBlock(el);
    }


    //
    // Events
    //

    if (typeof hljs !== 'undefined' && toggle) {
      [].forEach.call(toggle, function(el) {
        init(el);
      });
    }

  })();//
  // map.js
  // Theme module
  //

  (function() {

    //
    // Variables
    //

    var map = document.querySelectorAll('[data-toggle="map"]');
    var accessToken = 'pk.eyJ1IjoiY2F6ZW5vdmlhIiwiYSI6ImNqejJ3aDI2MzBhdjkzbmtiN3pkdTBseGkifQ.gI1mpHSMBfaqxukgzp5HMQ';


    //
    // Methods
    //

    function init(el) {
      var elementOptions = el.dataset.options;
      elementOptions = elementOptions ? JSON.parse(elementOptions) : {};
      var defaultOptions = {
        container: el,
        style: 'mapbox://styles/mapbox/light-v9',
        scrollZoom: false,
        interactive: false
      }
      var options = Object.assign(defaultOptions, elementOptions);

      // Get access token
      mapboxgl.accessToken = accessToken;

      // Init map
      //new mapboxgl.Map(options);
    }

    //
    // Events
    //

    if (typeof mapboxgl !== 'undefined' && map) {
      [].forEach.call(map, function(el) {
        init(el);
      });
    }

  })();//
  // navbar.js
  // Theme module
  //

  'use strict';

  (function() {

    //
    // Variables
    //

    var navbar = document.querySelector('.navbar');
    var isLight = false;
    var isTogglable = navbar ? navbar.classList.contains('navbar-togglable') : false;


    //
    // Functions
    //

    function makeNavbarLight() {
      if (!isLight && isTogglable) {
        navbar.classList.remove('navbar-dark');
        navbar.classList.add('navbar-light');
        navbar.classList.add('bg-white');
        navbar.classList.add('border-bottom');

        isLight = true;
      }
    }

    function makeNavbarDark() {
      if (isLight && isTogglable) {
        navbar.classList.remove('navbar-light');
        navbar.classList.remove('bg-white');
        navbar.classList.remove('border-bottom');
        navbar.classList.add('navbar-dark');

        repaintNav();

        isLight = false;
      }
    }

    // Repaint hack for Safari overscroll bug
    function repaintNav() {
      navbar.style.display='none';
      navbar.offsetHeight;
      navbar.style.display='block';
    }

    function toggleNavbar(event) {
      var scrollTop = window.pageYOffset;

      if (scrollTop > 0 && !isLight) {
        makeNavbarLight();
      } else if (scrollTop == 0 || scrollTop < 0 && isLight) {
        makeNavbarDark();
      }
    }


    //
    // Events
    //

    if (navbar && isTogglable) {
      "load scroll".split(' ').forEach(function(e) {
        window.addEventListener(e, function(e) {
          var type = e.type;

          toggleNavbar(type);
        });
      });
    }

  })();//
  // polyfills.js
  // Theme module
  //

  'use strict';

  // SVG
  // Polyfill to solve shortcomings of SVG scaling in IE. Inspired by http://nicolasgallagher.com/canvas-fix-svg-scaling-in-internet-explorer/.

  (function() {

    //
    // Variables
    //

    var svg = document.querySelectorAll('.svg-shim > svg');


    //
    // Methods
    //

    function init(elem) {

      // Get element's fill value
      var color = window.getComputedStyle(elem, null).getPropertyValue('color');

      // Get SVG's outerHTML and prepare to use it in image
      var content = new XMLSerializer().serializeToString(elem);
      content = content.replace(/currentColor/g, color);
      content = content.replace(/\s\s+/g, ' ');
      content = content.replace(/</g, '%3C');
      content = content.replace(/>/g, '%3E');
      content = content.replace(/#/g, '%23');
      content = content.replace(/"/g, '\'');
      content = 'data:image/svg+xml,' + content;

      // Create a replacer image
      var img = document.createElement('img');
      img.src = content;
      img.alt = '...';

      // Replace SVG with image
      var parent = elem.parentNode;
      parent.appendChild(img);
      parent.removeChild(elem);
    }


    //
    // Events
    //

    // Only has affect in IE
    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
      [].forEach.call(svg, function(elem) {
        init(elem);
      });
    }

  })();//
  // popovers.js
  // Theme module
  //

  'use strict';

  (function() {

    //
    // Variables
    //

    var toggle = document.querySelectorAll('[data-toggle="popover"]');


    //
    // Functions
    //

    function init(toggle) {
      // TODO: we need to import popover to use this?
      //$(toggle).popover({
      //  template: '<div class="popover" role="tooltip"><div class="arrow"></div><h6 class="popover-header text-uppercase"></h6><div class="popover-body"></div></div>'
      //});
    }


    //
    // Events
    //

    if (toggle) {
      init(toggle);
    }

  })();//
  // pricing.js
  // Theme module
  //

  (function() {

    //
    // Variables
    //

    var toggle = document.querySelector('[data-toggle="price"]');
    var DURATION = 1;


    //
    // Functions
    //

    function update(e) {
      var input = e.target;
      var checked = input.checked;

      var target = input.dataset.target;
      var targets = document.querySelectorAll(target);

      [].forEach.call(targets, function(e) {
        var annual = e.dataset.annual;
        var monthly = e.dataset.monthly;

        var countUp = (!checked) ? new CountUp(e, monthly, annual, null, DURATION) : new CountUp(e, annual, monthly, null, DURATION);

        if (!countUp.error) {
          countUp.start();
        } else {
          console.error(countUp.error);
        }
      });
    }

    //
    // Events
    //

    if (typeof CountUp !== 'undefined' && toggle) {
      toggle.addEventListener('change', function(e) {
        update(e);
      });
    }

  })();//
  // smooth-scroll.js
  // Theme module
  //

  'use strict';

  (function() {

    //
    // Variables
    //

    var toggle = '[data-toggle="smooth-scroll"]';


    //
    // Functions
    //

    function init(toggle) {
      var options = {
        header: '.navbar.fixed-top',
        offset: '24'
      };

      // Init
      new SmoothScroll(toggle, options);
    }


    //
    // Events
    //

    if (typeof SmoothScroll !== 'undefined' && toggle) {
      init(toggle);
    }

  })();//
  // tooltip.js
  // Theme module
  //

  'use strict';

  (function() {

    //
    // Variables
    //

    var toggle = document.querySelectorAll('[data-toggle="tooltip"]');


    //
    // Functions
    //

    function init(toggle) {
      // TODO: we need to import tooltip.js to use this
      // $(toggle).tooltip();
    }


    //
    // Events
    //

    if (toggle) {
      init(toggle);
    }

  })();//
  // typed.js
  // Theme module
  //

  (function() {

    //
    // Variables
    //

    var toggle = document.querySelectorAll('[data-toggle="typed"]');


    //
    // Functions
    //

    function init(el) {
      var elementOptions = el.dataset.options;
      elementOptions = elementOptions ? JSON.parse(elementOptions) : {};
      var defaultOptions = {
        typeSpeed: 40,
        backSpeed: 40,
        backDelay: 1000,
        loop: true
      }
      var options = Object.assign(defaultOptions, elementOptions);

      // Init
      new Typed(el, options);
    }


    //
    // Events
    //

    if (typeof Typed !== 'undefined' && toggle) {
      [].forEach.call(toggle, function(el) {
        init(el);
      });
    }

  })();

  // fix AOS rendering issues
  window.addEventListener('load', AOS.refresh);
  var is_safari = navigator.userAgent.indexOf("Safari") > -1;
  if (is_safari) {
    window.dispatchEvent(new Event('resize'));
  }
});





// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
