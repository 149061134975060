import consumer from './consumer'

consumer.subscriptions.create("UserNotificationsChannel",
  {
    connected: function() {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },

    received: function(data) {
      var container = $('.notifications').find('.notification-' + data['type']);
      if(container.length === 0) {
        container = $('<div class="alert alert-info notification-' + data['type'] + '"></div>');
        $('.notifications').append(container);
      }
      if (container.data('status') !== data['status']) {
        container.data('status', data['status:'])
        if (data['status'] === 0) {
          container.html('<i class="fe fe-settings fe-spin"></i> ' + data['notification']);
        }
        else if (data['status'] === 1) {
          container.html('<i class="fe fe-check"></i> ' + data['notification'] + " <a href='/occumatches', style='color:white'>Click here</a> to view your occupation matches")
        }
      }
    }
  });
