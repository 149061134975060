
class SearchForm {
  FORM_ID = "search_form";

  init() {
    $(document).ready(() => {
      return this.addAutoPostBackHandlers();
    });
  }

  addAutoPostBackHandlers() {
    document.querySelectorAll("[name='occumatch_search_form[order_by]']").forEach((el) => {
      el.addEventListener('change', () => {
        this.form().submit()
      });
    });
    document.querySelectorAll("[name='occumatch_search_form[filter_by][]']").forEach((el) => {
      el.addEventListener('change', () => {
        this.form().submit()
      });
    });
  }

  form() {
    return $('#' + this.FORM_ID);
  }
}

export default SearchForm;