let $ = require('jquery');

class OccumatchTables {

  init() {
    $(document).ready(() => {
     // this.initCertificatesTable();
      this.initJobpostingsTable();
     // this.initTrainingsTable();
    });
  }

  initCertificatesTable() {
    $('.certificates-table').each(function() {
      var container = $(this);
      var tbody = container.find('tbody');
      var onet_code_cert = container.data('onetsoc-code');
      var onet_title = container.data('onetsoc-title');
      tbody.append("<tr><td colspan='2' style='text-align:center;padding:15px;'><i class='fe fe-refresh-cw fe-spin'></i></td></tr>");
      $.getJSON('/certificates/' + onet_code_cert).then(function(data) {
        if(data.data.length > 0) {
          tbody.empty();
          $.each(data.data, function() {
            var name = this["ceterms:name"]["en"] ? this["ceterms:name"]["en"] : this["ceterms:name"]["en-US"]
            tbody.append("<tr><td>" + name + "</td><td><a href='" + this["ceterms:subjectWebpage"] + "' target='_blank' class='btn btn-primary-soft mr-1 d-print-none'>Explore</a></td></tr>");
          });
        }
        else {
          tbody.find('td').text("No certificates found for " + onet_title + ".");
        }
      })
    })
  }

  initJobpostingsTable() {
     $('.jp-table').each(function() {
       var container = $(this);
       var tbody = container.find('tbody');
       var onet_code_jobs = container.data('onet-code');
       var state_code = container.data('state-code');
       tbody.append("<tr><td colspan='4' style='text-align:center;padding:15px;'><i class='fe fe-refresh-cw fe-spin'></i></td></tr>");
       $.getJSON(
         '/jobpostings/' + onet_code_jobs,
         {state: state_code}
        ).then(function(data) {
         tbody.empty();
         $.each(data, function() {
           tbody.append("<tr><td>" + this["JobTitle"] + "</td><td>" + this["Company"] + "</td><td>" + this["Location"] + "</td><td><a href='" + this["URL"] + "' target='_blank' class='btn btn-primary-soft mr-1 d-print-none'>Explore</a></td></tr>");
         });
       })
     })
   }

  initTrainingsTable() {
    $('.trainings-table').each(function() {
      var container = $(this);
      var tbody = container.find('tbody');
      var onet_code_trainings = container.data('onetsoc-code-trainings');
      var onet_title = container.data('onetsoc-title-trainings');
      tbody.append("<tr><td colspan='2' style='text-align:center;padding:15px;'><i class='fe fe-refresh-cw fe-spin'></i></td></tr>");
      $.getJSON('/trainings/' + onet_code_trainings).then(function(data) {
        if(data.data.length > 0) {
          tbody.empty();
          $.each(data.data, function() {
            tbody.append("<tr><td>" + this["ceterms:name"]["en"] + "</td><td><a href='" + this["ceterms:subjectWebpage"] + "' target='_blank' class='btn btn-primary-soft mr-1 d-print-none'>Explore</a></td></tr>");
          });
        }
        else {
          tbody.find('td').text("No trainings found for " + onet_title + ".");
        }
      })
  })
  }
}

export default OccumatchTables;