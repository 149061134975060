let $ = require('jquery');

class ResumeSkills {
  init() {
    $(() => {
      if($('.resume-skills').length != 0) {
        this.addEventHandlers();
      }
    })
  }

  addEventHandlers() {
    $('.resume-skills').on('ajax:success', (evt) => {
      $('.resume-skills').html(evt.detail[0].querySelector('.resume-skills').innerHTML)
    })
  }
}

export default ResumeSkills;